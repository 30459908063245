import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import 'antd/dist/antd.min.css';
import { ThemeProvider } from 'styled-components';
import { COLORS } from './styles/colors';
// import { useBodyNoScript, useHeadScript } from './common/utils/useScript';
import Router from './router';
import i18n from './translation';
import { DevSupport } from '@react-buddy/ide-toolbox';
import { ComponentPreviews, useInitial } from './dev';
// import { createTheme } from '@mui/material/styles';
// import {ThemeProvider} from "@mui/material";
// import RalewayWoff2 from '/fonts/Motiva-Sans-Light.ttf';

// const theme = createTheme({
//     palette: {
//         primary: {
//             light: '#5c6bc0',
//             main: '#304ffe',
//             dark: '#1a237e',
//             contrastText: '#fff',
//         },
//         secondary: {
//             light: '#ff7961',
//             main: '#f44336',
//             dark: '#ba000d',
//             contrastText: '#000',
//         },
//     },
//         typography: {
//             fontFamily: 'Motiva, Arial',
//         },
//         components: {
//             MuiCssBaseline: {
//                 styleOverrides: `
//         @font-face {
//           font-family: 'Motiva';
//           font-style: normal;
//           font-display: swap;
//           font-weight: 400;
//           src: local('Motiva'), local('Motiva-Sans-Light'), url("/fonts/Motiva-Sans-Light.ttf") format('ttf');
//           unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
//         }
//       `,
//             },
//         }
// });

const theme = {
  colors: COLORS,
};

const App = () => {
  // useEffect(() => {
  //   logEvent(analytics, 'test');
  // }, []);

  // useHeadScript(
  //   "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
  //     "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
  //     "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
  //     "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
  //     "})(window,document,'script','dataLayer','GTM-N3Q75TT2');"
  // );
  //
  // useBodyNoScript(
  //   `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N3Q75TT2" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  // );

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
    <App />
  </DevSupport>,
  document.getElementById('root')
);
